<template>
    <div class="all-categories">
        <div class="all-categories__inner">
            <button
                type="button"
                class="all-categories__item"
                :class="{active: SELECTED_CATEGORY == 'Все'}"
                @click="selectCategory('Все')">Все</button>
            <button 
                v-for="category in CATEGORIES"
                :key="category.id"
                type="button"
                class="all-categories__item"
                :class="{active: SELECTED_CATEGORY == category.name}"
                @click="selectCategory(category.name)">{{category.name}}</button>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    data() {
        return { 
            
        }
    },
    methods: {
        ...mapActions([
            'GET_CATEGORIES',
            'RESET_PAGE_NUMBER',
            'GET_CATEGORY_VALUE',
            'SELECT_CATEGORY',
            'GET_PRODUCTS',
            'RESET_SEARCH_VALUE'
        ]),
        selectCategory(category){
            console.log(category);
            
            this.RESET_SEARCH_VALUE(); 
            this.RESET_PAGE_NUMBER()
            this.SELECT_CATEGORY(category);
            this.GET_PRODUCTS();
            this.$router.push({query: {'category': category, 'page': 1}})
        }
    },
    computed: {
        ...mapGetters([
            'CATEGORIES',
            'SELECTED_CATEGORY'
        ])
    },
    mounted() {
        this.GET_CATEGORIES()
    },
}
</script>

<style scoped>
    .all-categories__inner{
        display: flex;
        flex-wrap: wrap;
    }
    .all-categories__item{
        font-family: "Bona Nova SC", serif;
        padding: 5px 15px;
        color: #595959;
        font-weight: 700;
        margin-right: 20px;
        margin-bottom: 15px;
        border-radius: 5px;
        transition: all linear .2s;
    }
    .all-categories__item:hover{
        background-color: #F0EEEB;
    }
    .active{
        background-color: #F0EEEB;
    }
    @media (max-width: 600px) {
        .all-categories__inner{
            flex-wrap: nowrap;
            align-items: center;
            overflow-x: scroll;
        }
        .all-categories__item{
            white-space: nowrap
        }
        
    }
</style>