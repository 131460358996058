<template>
    <div class="popular-products">
        <div class="catalog-notification">
            <CartNotification :messages="messages"/>
        </div>

        <div class="popular-products__inner container">
            <h1 class="popular-products__heading heading">Самые популярные</h1>
            <div class="popular-products__slider-wrapper">
                <swiper
                    :slides-per-view="slides"
                    :space-between="30"
                    :modules="modules"
                    :freeMode="isFree"
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                    @reachEnd="reachEnd"
                    @reachBeginning="reachBeginning"
                    class="popular-products__slider slider"
                    :navigation="{
                        prevEl: previos,
                        nextEl: nextt,
                      }"
                    :pagination="{clickable: false}"
                    >
                    <swiper-slide 
                        class="slider__slide"
                        v-for="flower in POPULAR_PRODUCTS"
                        :key="flower.id">
                        <CatalogItem
                            class="slider__item"
                            :product_data="flower"
                            @addToCart="addToCart"
                            @showProduct="showProduct"
                            @openSeason="openSeason"/>
                    </swiper-slide>
                </swiper>
                <div ref="previos" class="swiper-button-prev"></div>
                <div ref="nextt" class="swiper-button-next"></div>
            </div>

            <!-- <div class="popular-products__slider-wrapper" v-if="isMob">
                <swiper
                    :slides-per-view="2"
                    :space-between="20"
                    :modules="modules"
                    @swiper="onSwiper"
                    @slideChange="onSlideChange"
                    @reachEnd="reachEnd"
                    @reachBeginning="reachBeginning"
                    class="popular-products__slider slider"
                    :navigation="{
                        prevEl: previos,
                        nextEl: nextt,
                      }"
                    :pagination="{clickable: false}"
                    >
                    <swiper-slide 
                        class="slider__slide"
                        v-for="flower in POPULAR_PRODUCTS"
                        :key="flower.id" >
                        <CatalogItem
                            :product_data="flower"
                            @addToCart="addToCart"
                            @showProduct="showProduct"
                            @openSeason="openSeason"/>
                    </swiper-slide>
                </swiper>
                <div ref="previos" class="swiper-button-prev"></div>
                <div ref="nextt" class="swiper-button-next"></div>
            </div> -->

            <div :class="{show: !isProductOpened}">
                <ProductPopup
                    @oneClickOrder="oneClickOrder"
                    @closePopup="closePopup"/>
            </div>
            
            <div :class="{show: !isOneClickOrder}">
            <OneClickPopup
                @closeOneClick="closeOneClick"/>
        </div>
        </div>
        <SeasonPopup
            v-if="seasonOpened"
            @closeSeason="closeSeason"
            @addSeasonToCart="addSeasonToCart"/>
    </div>
</template>

<script>
import SeasonPopup from '@/components/popups/Season-popup'
import CatalogItem from '@/components/Catalog-item'
import OneClickPopup from '@/components/popups/One-click-popup'
import {mapActions, mapGetters} from 'vuex'
import ProductPopup from '@/components/Product-popup'
import CartNotification from '@/components/Cart-notification';
import { Navigation, FreeMode} from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { ref } from 'vue';
import 'swiper/css';
import 'swiper/css/bundle';
import 'swiper/css/navigation';

export default {
    setup() {
        const onSwiper = (swiper) => {
            
        };
        const onSlideChange = () => {
            
        };
        const reachEnd = () => {
            
        };
        const reachBeginning = () => {
            
        };
        const previos = ref(null);
        const nextt = ref(null);
        return {
            onSwiper,
            onSlideChange,
            reachEnd,
            reachBeginning,
            modules: [Navigation, FreeMode],
            previos,
            nextt,
        };
    },
    data() {
        return {
            isFree: false,
            slides: 4,
            isOneClickOrder: false,
            isProductOpened: false,
            oneClickProduct: '',
            product: '',
            messages: [],
            isLastSlide: false,
            isMob: false,
            seasonOpened: false
        }
    },

    components:{
        CatalogItem,
        ProductPopup,
        CartNotification,
        OneClickPopup,
        SeasonPopup,
        Swiper,
        SwiperSlide
    },
    computed: {
        ...mapGetters([
            'POPULAR_PRODUCTS'
        ])
    },
    methods: {
        ...mapActions([
            'GET_POPULAR_PRODUCTS',
            'ADD_TO_CART'
        ]),
        // reachBeginning(){
        //     // this.isLastSlide = false
        // },
        oneClickOrder(data){
            this.isOneClickOrder = true
            this.oneClickProduct = data
            document.body.style.overflowY = 'hidden'
        },
        closeSeason(){
            this.seasonOpened = false
        },
        openSeason(data){
            this.product = data
            this.seasonOpened = true
        },
        addSeasonToCart(){
            this.ADD_TO_CART(this.product)
            this.addToCart()
        },
        // reachEnd(){
        //     this.isLastSlide = true
        //     console.log('конец');
        //     // this.isFirstSlide = true
        // },
        closeOneClick(bool){
            this.isOneClickOrder = bool
            document.body.style.overflowY = 'scroll'
        },
        closePopup(bool){
            this.isProductOpened = bool
            document.body.style.overflowY = 'scroll'
        },
        showProduct(bool){
            this.isProductOpened = bool
            document.body.style.overflowY = 'hidden'
        },
        addToCart(){
            let timeStamp = Date.now().toLocaleString()
            this.messages.unshift(
                {name: "Товар добавлен в корзину", id: timeStamp}
            )
        }
    },
    beforeMount() {
        if(window.screen.width <= 600){
            this.isFree = true
            this.slides = 'auto'
        }
    },
    mounted() {
        this.GET_POPULAR_PRODUCTS()
    },
}
</script>

<style scoped>
    @font-face {
        font-family: 'MADE TheArtist Script';
        src: local('MADE TheArtist Script'), local('MADETheArtistScript'), url('../fonts/Madetheartistscript.woff2') format('woff2'), url('../fonts/Madetheartistscript.woff') format('woff'), url('../fonts/Madetheartistscript.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
    }
    .popular-products__heading{
        position: relative;
        margin-bottom: 100px;
        text-align: left;
    }
    .popular-products__heading::after{
        position: absolute;
        content: "букеты";
        top: -10px;
        left: 365px;
        font-family: 'MADE TheArtist Script', sans-serif;
        text-transform: lowercase;
        font-weight: 400;
        font-size: 96px;
        color: #A875D2;
    }
    .popular-products__slider-wrapper{
        position: relative;
    }
    .slider__slide{
        width: 100%;
        /* max-width: 285px; */
    }
    .swiper-button-prev{
        color: #ACACAC;
        left: -50px;
    }
    .swiper-button-next{
        right: -50px;
        color: #ACACAC;
    }
    .slider-deco{
        position: absolute;
        top: 0;
        height: 100%;
        width: 170px;
    }
    .bg-left{
        left: 0;
        background: linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
        z-index: 10;
        display: none;
    }
    .bg-right{
        right: 0;
        background: linear-gradient(270deg, #FFF 0%, rgba(255, 255, 255, 0.00) 50%);
        z-index: 10;
    }
    .show{
        display: none;
    }
    .showBg{
        display: block;
    }
    .hide{
        display: none;
    }
    .mob{
        display: none;
    }
    @media (max-width: 1335px){
        .swiper-button-prev{
            left: auto;
            right: 80px;
            top: -80px;
        }
        .swiper-button-next{
            right: 0px;
            top: -80px;
        }
    }
    @media (max-width: 600px) {
        .popular-products__slider-wrapper{
            display: flex;
        }
        .popular-products__heading{
            max-width: 300px;
            margin-bottom: 85px;
        }
        .popular-products__heading::after{
            left: 140px;
            top: 35px;
            font-size: 70px;
        }
        .slider__slide{
            max-width: none;
            height: 100%;
            max-width: 250px;
        }
        .swiper-button-prev,
        .swiper-button-next{
            display: none;
        }
        .slider-deco{
            width: 70px;
        }
        .slider__item{
            height: 100%;
        }
    }
</style>