<template>
    <div class="sort">
        <div class="sort__inner">
            <div class="sort__select">
                <SortSelect/>
            </div>
        </div>
    </div>
</template>

<script>
import RangeSort from '@/components/Range-sort'
import SortSelect from '@/components/Sort-select'

export default {
    components: {
        RangeSort,
        SortSelect
    }
}
</script>

<style scoped>
    .sort__inner{
        display: flex;
        justify-content: space-between;
    }
    .sort__range{
        margin-right: 20px;
    }
    @media (max-width: 768px){
        .sort__inner{
            flex-direction: column;
        }
        .sort__range{
            margin-right: 0;
            margin-bottom: 30px;
        }
    }
</style>