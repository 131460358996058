<template>
    <div class="catalog-item">
        <img class="catalog-item__img" :src="'https://dff24.ru:8443/' + product_data.url" @click="productClick(product_data)">
        <p class="catalog-item__name">{{product_data.name}}</p>
        <p 
            class="catalog-item__views"
            v-if="curRouter == 'mostpopular'">Просмотры: {{product_data.views}}</p>
        <p 
            class="catalog-item__views"
            v-if="curRouter == 'mostorder'">Заказы: {{product_data.orders}}</p>
        <div class="catalog-item__footer">
            <div class="catalog-item__price price" :class="{priceColumn: this.$route.name == 'main'}">
                <p class="price__main">{{product_data.price}}<span class="price__marked">₽</span></p>
                 
                <span class="catalog-item__price-notify" v-if="product_data.category == 'Цветы поштучно'">за 1 шт</span>
            </div>
            <!-- <button
                :class="{added: isAdded}" 
                class="catalog-item__btn" 
                @click="addToCart"
                v-if="curRouter != 'admin' && !product_data.isSeason"></button>  -->
            <button
                class="catalog-item__cart" 
                @click="addToCart"
                v-if="curRouter != 'admin' && !product_data.isSeason">В корзину</button> 
            
            <button
                v-if="curRouter != 'admin' && product_data.isSeason"
                @click="openSeason" 
                class="catalog-item__question"></button>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
    data(){
        return{
            isAdded: false
        }
    },
    props: {
        product_data: {
            type: Object,
            default(){
                return{

                }
            }
        }
    },
    computed: {
        curRouter(){
            return this.$route.name
        },
        productPriceWithDiscount(){
            let price = this.product_data.price - this.product_data.price * 10 /100
            return price
        }
    },
    methods: {
        ...mapActions([
            'GET_ONE_PRODUCT',
            'ADD_TO_CART'
        ]),
        addToCart(){
            this.ADD_TO_CART(this.product_data)
            this.$emit('addToCart', null)
            this.isAdded = true
        },
        productClick(data){
            this.$router.push({name: 'product', params: {'flower': data.pageUrl}})
            // this.$router.push({name: 'clientprod', query: {'clientprod': data.id}})  
            // this.$router.push({name: 'clientprod', query: {'clientprod': data.id}})  
            // this.GET_ONE_PRODUCT(data)
            // this.$emit('showProduct', true) 
            // axios.put(`https://dff24.ru:8443/api/goods/view/${data.id}`)
        },
        openSeason(){
            this.$emit('openSeason', this.product_data)
        }
    },
}
</script>

<style scoped>
    .catalog-item{
        display: flex;
        flex-direction: column;
        font-family: "Bona Nova SC", serif;
        max-height: 550px;
    }
    .catalog-item__img{
        width: 100%;
        aspect-ratio: 1 / 1;
        margin-bottom: 15px;
        transition: all linear .2s;
        border-radius: 12px;
    }
    .catalog-item__img:hover{
        transform: scale(.95);
        cursor: pointer;
    }
    .catalog-item__name{
        font-size: 20px;
        margin-bottom: 20px;
        color: #595959;
    }
    .catalog-item__price{
        font-size: 24px;
        margin-right: 15px;
    }
    .catalog-item__price-notify{
        font-size: 16px;
    }
    .catalog-item__footer{
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .catalog-item__btn{
        height: 55px;
        width: 55px;
        background: #ECDEF6 url(../images/icons/sprite-2.svg) no-repeat;
        background-position: 53% -20%;
        border-radius: 50%;
        transition: all linear .1s;
    }
    .catalog-item__btn:hover{
        background-position-y: 53%;
    }
    .catalog-item__question{
        height: 55px;
        width: 55px;
        background: #eee url(../images/icons/question-2.svg) no-repeat;
        background-size: cover;
        background-position: center;
        border-radius: 50%;
        transition: all linear .2s;
    }
    .catalog-item__question:hover{
        opacity: .6;
    }
    .catalog-item__views{
        margin-top: 10px;
        color: #707070;
    }
    .added{
        background-position-y: 120%;
    }
    .cross{
        text-decoration: line-through;
        text-decoration-thickness: 2px;
    }
    .price__main_discount{
        margin-left: 25px;
    }
    .priceColumn{
        display: flex;
        flex-direction: column;
    }
    .undoMargin{
        margin-left: 0;
    }
    .outOfStock{
        opacity: .5;
        pointer-events: none;
    }
    .price__marked{
        font-size: 22px;
    }
    .catalog-item__cart{
        padding: 8px 15px;
        border: 1px solid #A6A6A6;
        border-radius: 12px;
        color: #595959;
        font-family: "Bona Nova SC", serif;
        font-size: 20px;
        transition: all linear .2s;
    }
    .catalog-item__cart:hover{
        background-color: #EFE7F5;
    }
    @media (max-width: 992px){
        .catalog-item__name{
            font-size: 16px;
        }
        .catalog-item__price{
            font-size: 20px;
        }
        .catalog-item__btn{
            text-transform: none;
        }
    }
    @media (max-width: 768px) {
        .catalog-item__price{
            font-size: 18px;
        }
        .catalog-item__btn{
            font-size: 16px;
            margin-right: 0;
        }
    }
    @media (max-width: 600px){
        .catalog-item__img{
            margin-bottom: 15px;
        }
        .catalog-item__name{
            font-size: 18px;
        }
        .catalog-item__price{
            font-size: 20px;
        }
        .price__marked{
            font-size: 18px;
        }
        .catalog-item__btn{
            font-size: 18px;
            text-transform: uppercase;
        }
        .catalog-item{
            height: 100%;
        }
        .catalog-item__cart{
            padding: 8px 40px;
            font-size: 18px;
        }
    }
</style>