<template>
    <div class="header">
  
            <div class="header__inner">
                <img 
                    class="header__logo" 
                    src="../images/logo.svg" 
                    @click="goToMainPage">
                <nav
                    :class="{show: isActive}" 
                    class="navigation header__navigation" 
                    v-if="this.$route.name !== 'admin'">

                    <router-link 
                        to="/about" 
                        class="navigation__link">О компании</router-link>

                    <router-link 
                        to="/delivery" 
                        class="navigation__link">Доставка и оплата</router-link>

                    <router-link 
                        to="/subscribe" 
                        class="navigation__link">Подписка</router-link>

                    <router-link 
                        to="/contacts" 
                        class="navigation__link navigation__link-contacts">Контакты</router-link>

                    

                    <a href="tel:+78123091999" class="navigation__tel">+7 (812) 309-19-99</a>

                    <div class="header__social-mob social">
                        <a href="https://vk.com/dff24" class="social__link social__link_vk"></a>
                        <a href="https://t.me/+79219548941" class="social__link social__link_tg"></a>
                        <a href="https://api.whatsapp.com/send?phone=79219548941" class="social__link social__link_wa"></a>
                        <a href="https://www.instagram.com/dff24ru/" class="social__link social__link_inst"></a>
                    </div>
                </nav>
 
                
                <div class="header__btns" v-if="this.$route.name !== 'admin'">

                    <div class="header__tel tel">
                        <div class="tel__inner">
                            <p class="tel__number">+7 (812) 309-19-99</p>
                            <div class="tel__social">
                                <a href="https://vk.com/dff24" class="tel__social-item tel__vk"></a>
                                <a href="https://t.me/+79219548941" class="tel__social-item tel__tg"></a>
                                <a href="https://api.whatsapp.com/send?phone=79219548941" class="tel__social-item tel__wa"></a>
                                <a href="https://www.instagram.com/dff24ru/" class="tel__social-item tel__inst"></a>
                            </div>
                        </div>

                        <span class="tel__ico"></span>
                    </div>

                    <button 
                        class="header__lk"
                        @click="openPopup"></button>

                    <router-link 
                        to="/cart" 
                        class="cart header__cart">
                        <span class="cart__quantity">{{CART.length}}</span>
                    </router-link>
                </div>
                
                <div 
                    class="burger-ico" 
                    :class="{menuOpen: isActive}"
                    @click="toggleBurger">
                        <span class="burger-ico__item"></span>
                </div>
            </div>
        

        <LoginPopup
            v-if="isLogin"
            @closePopup= "closePopup"
            @selectRegistration= "selectRegistration"/>
        <RegistrationPopup
            v-if="isRegistration"
            @closePopup= "closePopup"
            @selectLogin= "selectLogin"
            @success = "success"/>
        <SuccesssPopup
            v-if="isSuccess"
            />
    </div>
</template>

<script>
import LoginPopup from '@/components/popups/Login-popup'
import RegistrationPopup from '@/components/popups/Registration-popup'
import SuccesssPopup from '@/components/popups/Success-popup'
import {mapGetters} from 'vuex'

export default {
    data() {
        return {
            isActive: false,
            isLogin: false,
            isRegistration: false,
            isSuccess: false
        }
    },
    methods: {
        goToMainPage(){
            if(this.$route.name !=='main'){
                this.$router.push('/')
            }
        },
        closePopup(bool){
            this.isLogin = bool
            this.isRegistration = bool
        },
        openPopup(){
            this.isLogin = true
            let cookie = document.cookie.match(/token=(.+?)(;|$)/)
            let role = localStorage.getItem('role')
            if(cookie && role == 'user'){
                this.$router.push('/account')
            }else if(cookie && role == 'admin'){
                this.$router.push('/admin')
            }
        },
        selectRegistration(){
            this.isLogin = false
            this.isRegistration = true
        },
        selectLogin(){
            this.isLogin = true
            this.isRegistration = false
        },
        success(){
            this.isRegistration = false
            this.isSuccess = true
        },
        toggleBurger(){
            this.isActive = !this.isActive
        },
    },
    components: {
        LoginPopup,
        RegistrationPopup,
        SuccesssPopup
    },
    computed: {
        ...mapGetters([
            'CART' 
        ]),
    },
}
</script>

<style scoped>
    .navigation__tel{
        display: none;
    }
    /* .header{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 1000;
    } */
    .altHeader{
        background-color: #F6F1FA;
        position: relative;
    }
    .header__inner{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 100px;
        padding-right: 100px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .header__logo{
        max-width: 130px;
        margin-right: 20px;
        cursor: pointer;
    }
    .header__navigation{
        display: flex;
    }
    .navigation__link{
        font-family: "Bona Nova SC", serif;
        text-decoration: none;
        font-size: 20px;
        margin-right: 40px;
        position: relative;
        color: #595959;
    }
    .navigation__link-contacts{
        margin-right: 0;
    }
    .navigation__link::after{
        position: absolute;
        content: "";
        height: 2px;
        width: 24px;
        background-color: #000;
        border-radius: 25px;
        bottom: -5px;
        left: 50%;
        margin-left: -12px;
        transition: opacity linear .2s;
        opacity: 0;
    }
    .navigation__link:hover::after{
        opacity: 1;
    }
    .navigation__link:active::after{
        width: 100%;
        left: 0;
        margin: 0;
    }
    .header__btns{
        display: flex;
        align-items: center;
        margin-left: 20px;
    }
    .header__tel{
        position: relative;
        display: flex;
        justify-content: flex-end;
    }
    .header__tel:hover .tel__inner{
        opacity: 1;
    }
    .tel__inner{
        position: absolute;
        z-index: 50;
        padding: 20px 25px;
        top: 0;
        right: 0;
        border-radius: 31px;
        border: 1px solid rgba(255, 255, 255, 0.24);
        background: rgba(228, 228, 228, 0.49);
        backdrop-filter: blur(25.049999237060547px);
        opacity: 0;
        transition: all linear .2s;
    }
    .tel__number{
        display: inline-block;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #000;
    }
    .tel__social{
        display: flex;
    }
    .tel__social-item{
        height: 36px;
        width: 36px;
        border-radius: 50%;
        margin-right: 25px;
        transition: all linear .2s;
    }
    
    .tel__social-item:hover{
        background-color: #BD91DE;
    }
    .tel__social-item:last-child{
        margin-right: 0;
    }
    .tel__vk{
        background: #C8A2E5 url(../images/icons/vk-white-ico.svg) no-repeat;
        background-size: 50%;
        background-position: center;
    }
    .tel__tg{
        background: #C8A2E5 url(../images/icons/tg-white-ico.svg) no-repeat;
        background-size: 50%;
        background-position: center;
    }
    .tel__wa{
        background: #C8A2E5 url(../images/icons/wa-white-ico.svg) no-repeat;
        background-size: 50%;
        background-position: center;
    }
    .tel__inst{
        background: #C8A2E5 url(../images/icons/insta-white-ico.svg) no-repeat;
        background-size: 50%;
        background-position: center;
    }
    .tel__ico{
        display: inline-block;
        height: 62px;
        width: 62px;
        border-radius: 50%;
        background: url(../images/icons/tel-3.svg) no-repeat;
        background-position: center;
        transition: all linear .2s;
        z-index: 100;
        margin-right: 15px;
    }
    .tel__ico:hover{
        background-color: #E9E9E9;
    }
    .header__lk{
        height: 88px;
        width: 88px;
        border-radius: 50%;
        background: url(../images/icons/lk-ico.svg) no-repeat;
        background-position: center;
        transition: all linear .2s;
        cursor: pointer;
    }
    .header__lk:hover{
        background-color: rgba(244, 170, 188, .5);
    }
    .header__cart{
        position: relative;
        height: 88px;
        width: 88px;
        border-radius: 50%;
        background: url(../images/icons/cart-ico.svg) no-repeat;
        background-position: center;
        text-decoration: none;
        transition: all linear .2s;
    }
    .cart__quantity{
        position: absolute;
        display: block;
        height: 23px;
        width: 23px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        border-radius: 50%;
        color: #fff;
        background-color: #000;
        bottom: 18px;
        right: 15px;
    }
    .header__cart:hover{
        background-color: rgba(244, 170, 188, .5);
    }

    .logo_shadow{
        box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.30);
    }
    /* бургер */
    .burger-ico{
        display: none;
        align-items: center;
    }
    .burger-ico__item{
        position: relative;
        width: 40px;
        height: 4px;
        background-color: #000;
    }
    .burger-ico__item::before{
        content: "";
        position: absolute;
        background-color: #000;
        width: 40px;
        height: 4px;
        transform: translate(0, 10px);
        transition: all ease .2s;
    }
    .burger-ico__item::after{
        content: "";
        position: absolute;
        background-color: #000;
        width: 40px;
        height: 4px;
        transform: translate(0, -10px);
        transition: all ease .2s;
    }
    .menuOpen span{
        transform: rotate(45deg);
    }
    .menuOpen span::before{
        transform: rotate(-90deg);
    }
    .menuOpen span::after{
        transform: translate(0);
    }
    .header__social-mob{
        display: none;
    }
    /* Адаптив */
    @media (max-width: 1240px){
        .header__inner{
            padding-left: 15px;
            padding-right: 15px;
        }
        .header__navigation{
            margin-right: 0;
        }
    }
    @media (max-width: 992px){
        .header__tel{
            display: none;
        }
        .navigation__link{
            font-size: 14px;
            margin-right: 20px;
        }
    }
    @media (max-width: 768px){
        .header__inner{
            padding-top: 15px;
            padding-bottom: 15px;
        }
        .navigation__link{
            margin-right: 15px;
        }
        .header__lk,
        .header__cart{
            height: 40px;
            width: 40px;
        }
        .header__cart{
            margin-left: 20px;
        }
        .cart__quantity{
            right: 0;
            bottom: 0;
        }
    }
    @media (max-width: 600px) {
        .header{
            position: relative;
        }
        .header__logo{
            max-width: 120px;
        }
        .header__lk{
            background-image: url(../images/icons/lk-ico.svg);
        }
        .header__cart{
            background-image: url(../images/icons/cart-ico.svg);
            margin-right: 30px;
        }
        .cart__quantity{
            color: #000;
            background-color: #C8A2E5;
            bottom: -5px;
            right: -5px;
        }
        .header__navigation{
            display: none;
            position: absolute;
            z-index: 1000;
            top: 100%;
            left: 0;
            width: 100%;
            align-items: center;
            background-color: #F6F1FA;
            flex-direction: column;
            padding-bottom: 60px;
        }
        .navigation__link{
            color: #000;
            margin-top: 25px;
            font-size: 16px;
            margin-right: 0;
        }
        .navigation__link-contacts{
            margin-bottom: 25px;
        }
        .navigation__tel{
            display: inline-block;
        }
        .header__social-mob{
            display: flex;
            justify-content: center;
            margin-top: 40px;
        }
        .social__link{
            height: 40px;
            width: 40px;
            margin-right: 30px;
        }
        .social__link:last-child{
            margin-right: 0;
        }
        .social__link_vk{
            background: url(../images/icons/vk-ico.svg) no-repeat;
            background-position: center;
        }
        .social__link_tg{
            background: url(../images/icons/tg-ico.svg) no-repeat;
            background-position: center;
        }
        .social__link_wa{
            background: url(../images/icons/wa-ico.svg) no-repeat;
            background-position: center;
        }
        .social__link_inst{
            background: url(../images/icons/insta-ico.svg) no-repeat;
            background-position: center;
        }
        .burger-ico{
            display: flex;
        }
        .show{
            display: flex;
        }
    }
</style>