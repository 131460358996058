<template>
    <div class="main-page">
        <transition name="fade">
            <PageLoading 
                v-if="!isPageLoaded"/>
        </transition> 

        <div class="main-page__header">
            <Header/>
        </div>
        <div class="main-page__banner">
            <MainBanner @scrollToBlock="scrollToCatalog"/>
        </div>
        <!-- <div class="main-page__search container">
            <Search/>
        </div> -->
        <div class="main-page__popular">
            <PopularProducts/>
        </div>
        <div class="main-page__categories">
            <MainCategories @scrollToBlock="scrollToCatalog"/>
        </div> 
        <div class="container main-catalog main-page__catalog" id="catalog" ref="catalog">
            <div class="main-catalog__head">
                <div class="main-catalog__wrapper">
                    <h1 class="main-catalog__heading">Каталог</h1>
                    <div class="main-catalog__search">
                        <Search/>
                    </div>
                    <!-- <span class="main-catalog__category">{{SELECTED_CATEGORY}}</span> -->
                </div>
            </div>
            <AllCategories class="main-page__all-categories"/>
            <!-- <div class="catalog-page__categories">
                <Categories/>
            </div> -->
            <div class="main-catalog__inner">
                <div class="main-catalog__catalog">
                    <Catalog id="catalog"/>
                </div>
            </div>
        </div>
        <!-- <div class="main-page__btn">
            <RoundedBtn
                @click="redirectToCatalog">Перейти в каталог</RoundedBtn>
        </div> -->
        <div class="main-page__delivery-banner container">
            <DeliveryBanner/>
        </div>
        <div class="main-page__footer">
            <Footer/>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Header from '@/components/Header'
import Search from '@/components/Search'
import DeliveryBanner from '@/components/Delivery-banner'
import MainCategories from '@/components/Main-categories'
import RoundedBtn from '@/components/UI/Rounded-btn'
import Footer from '@/components/Footer'
import MainBanner from '@/components/Main-banner'
import PopularProducts from '@/components/Popular-products'
import PageLoading from '@/components/Page-loading'
import Catalog from '@/components/Catalog'
import Categories from '@/components/Categories'
import AllCategories from '@/components/All-categories'

export default {
    data() {
        return {
            isPageLoaded: false
        }
    },
    components: {
        Header,
        MainBanner,
        Search,
        PopularProducts,
        DeliveryBanner,
        MainCategories,
        RoundedBtn,
        Footer,
        PageLoading,
        Catalog,
        Categories,
        AllCategories
    },
    computed: {
        ...mapGetters([
            'SELECTED_CATEGORY',
            'SELECTED_SUBCATEGORY'
        ])
    },
    methods: {
        ...mapActions([
            'RESET_PAGE_NUMBER',
            'RESET_SEARCH_VALUE',
            'GET_CATEGORY_VALUE',
            'RESET_CATEGORY',
            'SELECT_CATEGORY'
        ]),
        redirectToCatalog(){
            this.RESET_SEARCH_VALUE()
            this.RESET_PAGE_NUMBER()
            this.GET_CATEGORY_VALUE('')
            this.RESET_CATEGORY()
            this.SELECT_CATEGORY('Все');
            this.$router.push({name: 'catalog', query: {'category': 'Все', 'page': 1}})
        },
        scrollToCatalog(){
            this.$refs.catalog.scrollIntoView({ behavior: 'smooth' });
        },
    },
    
    // beforeCreate() {
    //     this.$router.push({query: {'category': 'Все', 'page': 1}})
    // },
    mounted() {
        document.body.style.overflowY = 'hidden'
        setTimeout(() => {
            document.body.style.overflowY = 'scroll'
            this.isPageLoaded=true
            }, 1500);
        //  document.onreadystatechange = () =>{
        //      if (document.readyState == "complete") {
        //          this.isPageLoaded = true
        //           document.body.style.overflowY = 'scroll'
        //      }
        //  }
    },
}
</script>

<style>
    .main-page__header{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        z-index: 9999;
    }
    .main-page__banner{
        margin-bottom: 60px;
    }
    .main-page__search{
        max-width: 880px;
        margin-top: 70px;
        margin-bottom: 110px;
    }
    .main-page__popular{
        margin-bottom: 120px;
    }
    .main-page__delivery-banner{
        margin-bottom: 140px;
    }
    .main-page__btn{
        display: flex;
        justify-content: center;
        margin-top: 50px;
        margin-bottom: 110px;
    }
    .fade-enter-from,
    .fade-leave-to {
        transition: all 1s linear;
        opacity: 0;
    }
    .main-page__catalog{
        margin-bottom: 110px;
    }
    .main-page__categories{
        margin-bottom: 50px;
    }
    .main-catalog__head{
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
    }
    .main-catalog__wrapper{
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        margin-right: 25px;
    }
    .main-catalog__category{
        position: relative;
        font-family: 'Playfair Display', serif;
        font-size: 24px;
        padding-left: 25px;
    }
    .main-catalog__category::before{
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -20px;
        left: 0;
        width: 1px;
        height: 40px;
        background-color: #000;
        transform: rotate(35deg);
    }
    .main-catalog__heading{
        font-size: 36px;
        font-family: 'Playfair Display', serif;
        text-transform: uppercase;
        margin-right: 25px;
        color: #595959;
    }
    .main-catalog__search{
        width: 100%;
        max-width: 395px;
    }
    .main-page__all-categories{
        margin-bottom: 60px;
    }
    @media (max-width: 600px) {
        .main-page__search{
            margin-top: 40px;
            margin-bottom: 60px;
        }
        .main-page__popular,
        .main-page__btn{
            margin-bottom: 50px;
        }
        .main-page__delivery-banner{
            overflow: hidden;
            padding-top: 60px;
            padding-bottom: 60px;
            margin-bottom: 0;
        }
        .main-page__banner{
            margin-bottom: 50px;
        }
        .main-catalog__head{
            flex-direction: column;
            margin-bottom: 0;
        }
        .main-catalog__heading{
            font-size: 20px;
            margin-right: 50px;
        }
        .main-catalog__category{
            font-size: 20px;
        }
        .main-catalog__wrapper{
            margin-bottom: 30px;
        }
        .main-page__all-categories{
            margin-bottom: 25px;
        }
    }
</style>