<template>
    <div class="popup">
        <div class="popup__inner">
            <div class="popup__form form">
                <button class="popup__close" @click="close"></button>
                <h1 class="form__heading heading">вход в личный кабинет</h1>
                <form 
                    class="form__enter enter-form" 
                    @submit.prevent="login">
                    <input 
                        placeholder="Введите Email" 
                        class="form__input input"
                        v-model="state.email">
                    <input 
                        placeholder="Пароль" 
                        class="form__input form__input_pass input" 
                        type="password"
                        v-model="state.password">
                    <!-- <button type="button" class="form__forgot-pass">Забыли пароль?</button> -->

                    <div class="enter-form__btns">
                        <button 
                            class="enter-form__enter enter-form__btn btn"
                            type="submit"
                            >{{submitBtnText}}</button>
                        <button 
                            class="enter-form__registration enter-form__btn btn"
                            type="button"
                            @click="selectRegistration">Регистрация</button>
                    </div>

                    <div class="checkbox enter-form__checkbox">
                        <span 
                            class="checkbox__checkbox"
                            :class="{checkbox_select: isRemember}"
                            @click="selectRemember"></span>
                        <input
                            id="remember" 
                            type="checkbox" 
                            class="checkbox__input"
                            >
                        <label 
                            for="remember"
                            class="checkbox__label"
                            @click="selectRemember">Запомнить меня на сайте</label>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import useValidate from '@vuelidate/core'
import { required} from '@vuelidate/validators'
import { reactive } from 'vue'

export default {
    setup(){
        const state = reactive({
            email: '',
            password: '',
        })
        const rules = {
            email: {required},
            password: {required},  
        }
        const v$ = useValidate(rules, state)
        return {state, v$}
    },
    data() {
        return {
            isRemember: false,
            isLoading: false
        }
    },
    methods: {
        selectRemember(){
            this.isRemember = !this.isRemember
        },
        close(){
            this.$emit('closePopup', false)
        },
        selectRegistration(){
            this.$emit('selectRegistration')
        },
        async login(){
            const isFormCorrect = await this.v$.$validate()
            if(!isFormCorrect){
                return alert('Ошибка в форме')
            }
            axios({ 
                method: 'POST',
                url: 'https://dff24.ru:8443/api/user/login',
                data: {
                    email: this.state.email,
                    password: this.state.password 
                }
            }).then((response)=>{ 
                this.isLoading = false
                localStorage.setItem('role', response.data.role)
                switch (response.data.message) {
                    case 'Такой пользователь не найден':
                        alert('Неверный логин')
                        break;
                    case 'Неверный пароль':
                        alert(response.data.message)
                        break;
                    default:
                        if(this.isRemember){
                            document.cookie = `token = ${response.data.token}; max-age=259200`
                        }else{
                            document.cookie = `token = ${response.data.token};` 
                        }
                        this.close()
                        if(response.data.role != 'admin'){
                            this.$router.push('/account')
                        }else{
                            this.$router.push('/admin')
                        } 
                    }
                })
        },
    },
    computed: {
        submitBtnText(){
            return this.isLoading ? 'Загрузка...' : 'Войти'
        }
    }
}
</script>

<style scoped>
    .popup__inner{
        position: fixed;
        display: flex;
        z-index: 100;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 60px;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%; 
        background-color: rgba(0, 0, 0, .5);
    }
    .popup__close{
        position: absolute;
        top: 45px;
        right: 60px;
        height: 20px;
        width: 20px;
    }
    .popup__close::before{
        position: absolute;
        content: "";
        height: 2px;
        width: 25px;
        background-color: #707070;
        top: 12px;
        right: 0;
        transform: rotate(45deg);
    }
    .popup__close::after{
        position: absolute;
        content: "";
        height: 2px;
        width: 25px;
        background-color: #707070;
        top: 12px;
        right: 0;
        transform: rotate(-45deg);
    }
    .popup__form{
        position: relative;
        background-color: #fff;
        padding: 80px 160px;
        border-radius: 30px;
        max-width: 880px;
    }
    .form__heading{
        margin-bottom: 50px;
    }
    /* Форма входа */
    .form__enter{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 455px;
    }
    .form__input{
        margin-bottom: 20px;
    }
    .form__input_pass{
        margin-bottom: 25px;
    }
    .form__forgot-pass{
        align-self: flex-start;
        margin-bottom: 40px;
        font-size: 16px;
        text-decoration: underline;
    }
    .enter-form__btns{
        display: flex;
        margin-bottom: 35px;
    }
    .enter-form__btn{
        width: 100%;
        padding: 15px 0;
    }
    .enter-form__enter{
        width: 100%;
        margin-right: 25px;
    }
    .enter-form__checkbox{
        display: flex;
        justify-content: center;
    }
    .checkbox__checkbox{
        position: relative;
        display: inline-block;
        vertical-align: baseline;
        height: 20px;
        width: 20px;
        margin-right: 20px;
        margin-bottom: -2px;
        border: 1px solid #000;
        border-radius: 4px;
        cursor: pointer;
    }
    .checkbox__input{
        display: none;
    }
    .checkbox__label{
        cursor: pointer;
    }
    .checkbox_select{
        background-color: #000;
        transition: all linear .1s;
    }
    .checkbox_select::before{
        position: absolute;
        content: "";
        height: 12px;
        width: 2px;
        top: 3px;
        right: 6px;
        transform: rotate(35deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    .checkbox_select::after{
        position: absolute;
        content: "";
        height: 7px;
        width: 2px;
        bottom: 4px;
        left: 5px;
        transform: rotate(-35deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    .invalid{
        border-color: red;
    }
    @media (max-width: 600px) {
        .popup__inner{
            padding: 0;
        }
        .popup__form{
            padding: 60px 15px 30px 15px;
        }
        .popup__close{
            top: 25px;
            right: 15px;
        }
        .form__heading{
            margin-bottom: 30px;
        }
        .form__input{
            margin-bottom: 15px;
        }
        .enter-form__btns{
            margin-top: 15px;
        }
        .checkbox__label{
            font-size: 14px;
        }
    }
</style>