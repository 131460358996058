<template>
    <div class="main-categories container">
        <div class="main-categories__wrapper">
            <h1 class="main-categories__heading heading">Популярные категории</h1>

            <swiper
                :slidesPerView="slides"
                :space-between="15"
                :modules="modules"
                :freeMode="isFree"
                :centeredSlides="isCentered"
                :initialSlide="initialSlide"
                :navigation="{
                            prevEl: '.swiper-button-prevv',
                            nextEl: '.swiper-button-nextt', 
                        }"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
                class="main-categories__slider categories-slider">

                <swiper-slide class="categories-slider__slide">
                    <div 
                        class="categories__item item" 
                        @click="redirectToCatalog('Авторские букеты', '', 0)"
                        :class="{activeCategory: SELECTED_CATEGORY=='Авторские букеты'}">
                        <img class="item__img" src="../images/main-categories/auth.png">

                        <div class="item__content">
                            <h1 class="item__name">Авторские букеты</h1>
                            <p class="item__price">от 4 245 рублей</p>
                        </div>
                    </div>
                </swiper-slide>

                <swiper-slide class="categories-slider__slide">
                    <div 
                        class="categories__item item" 
                        @click="redirectToCatalog('Цветочные композиции', '', 1)"
                        :class="{activeCategory: SELECTED_CATEGORY=='Цветочные композиции'}">
                        <img class="item__img" src="../images/main-categories/comp.png">
                        
                        <div class="item__content">
                            <h1 class="item__name">цветочные композиции</h1>
                            <p class="item__price">от 4 180 рублей</p>
                        </div>
                    </div>
                </swiper-slide>

                <swiper-slide class="categories-slider__slide">
                    <div 
                        @click="redirectToCatalog('Букеты', '', 2)"
                        class="categories__item item"
                        :class="{activeCategory: SELECTED_CATEGORY=='Букеты'}">
                        <img class="item__img" src="../images/main-categories/buq.png">

                        <div class="item__content">
                            <h1 class="item__name">букеты</h1>
                            <p class="item__price">от 3 420 рублей</p>
                        </div>
                    </div>
                </swiper-slide>

                <swiper-slide class="categories-slider__slide">
                    <div 
                        class="categories__item item" 
                        @click="redirectToCatalog('Монобукеты', '', 3)"
                        :class="{activeCategory: SELECTED_CATEGORY=='Монобукеты'}">
                        <img class="item__img" src="../images/main-categories/mon.png">
                        
                        <div class="item__content">
                            <h1 class="item__name">Монобукеты</h1>
                            <p class="item__price">от 3 500 рублей</p>
                        </div>
                    </div>  
                </swiper-slide>

                <swiper-slide class="categories-slider__slide">
                    <div 
                        class="categories__item item" 
                        @click="redirectToCatalog('Цветы поштучно', 'Хризантема', 4)"
                        :class="{activeCategory: currentFlower=='Хризантема'}">
                        <img class="item__img" src="../images/main-categories/chris.png">

                        <div class="item__content">
                            <h1 class="item__name">хризантемы</h1>
                            <p class="item__price">от 177 рублей</p>
                        </div>
                    </div>
                </swiper-slide>

                <swiper-slide class="categories-slider__slide">
                    <div 
                        class="categories__item item" 
                        @click="redirectToCatalog('Цветы поштучно', 'Роза', 5)"
                        :class="{activeCategory: currentFlower=='Роза'}">
                        <img class="item__img" src="../images/main-categories/ros.png">
                        
                        <div class="item__content">
                            <h1 class="item__name">Розы</h1>
                            <p class="item__price">от 209 рублей</p>
                        </div>
                    </div>
                </swiper-slide>

                <swiper-slide class="categories-slider__slide">
                    <div 
                        class="categories__item item" 
                        @click="redirectToCatalog('Цветы поштучно', '', 6)"
                        :class="{activeCategory: currentFlower=='Цветы'}">
                        <img class="item__img" src="../images/main-categories/one.png">

                        <div class="item__content">
                            <h1 class="item__name">цветы поштучно</h1>
                            <p class="item__price">от 90 рублей</p>
                        </div>
                    </div>
                </swiper-slide>

                <swiper-slide class="categories-slider__slide">
                    <div 
                        class="categories__item item" 
                        @click="redirectToCatalog('Свадебные букеты', '', 7)"
                        :class="{activeCategory: SELECTED_CATEGORY=='Свадебные букеты'}">
                        <img class="item__img" src="../images/main-categories/wed.png">
                        
                        <div class="item__content">
                            <h1 class="item__name">Свадебные букеты</h1>
                            <p class="item__price">скоро будут!</p>
                        </div>
                    </div>
                </swiper-slide>

                <swiper-slide class="categories-slider__slide">
                    <div 
                        class="categories__item item" 
                        @click="redirectToCatalog('Открытки', '', 8)"
                        :class="{activeCategory: SELECTED_CATEGORY=='Открытки'}">
                        <img class="item__img" src="../images/main-categories/car.png">

                        <div class="item__content">
                            <h1 class="item__name">Открытки</h1>
                            <p class="item__price">от 350 рублей</p>
                        </div>
                    </div>
                </swiper-slide>

                <!-- <swiper-slide class="categories-slider__slide">
                    <div 
                        class="categories__item item" 
                        @click="redirectToCatalog('Мягкие игрушки', '', 9)"
                        :class="{activeCategory: SELECTED_CATEGORY=='Мягкие игрушки'}">
                        <img class="item__img" src="../images/main-categories/toy.png">
                        
                        <div class="item__content">
                            <h1 class="item__name">Мягкие игрушки</h1>
                            <p class="item__price">От 440 р</p>
                        </div>
                    </div>
                </swiper-slide> -->

                <swiper-slide class="categories-slider__slide">
                    <div 
                        class="categories__item item" 
                        @click="redirectToCatalog('Букеты до 3000', '', 9)"
                        :class="{activeCategory: SELECTED_CATEGORY=='Букеты до 3000'}">
                        <img class="item__img" src="../images/main-categories/3000.png">
                        
                        <div class="item__content">
                            <h1 class="item__name">Букеты <br><span class="item__name_fsz">до</span> <span class="item__name_price">3000</span></h1>
                        </div>
                    </div>
                </swiper-slide>

            </swiper>
            <div class="controlls__wrapper">
                <button 
                    type="button"
                    class="categories__select-btn"
                    @click="selectCategory">Выбрать категорию</button>
                <div class="slider__controlls">
                    <div class="swiper-button-prevv"></div>
                    <div class="swiper-button-nextt"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { ref } from 'vue';
import {mapActions, mapGetters} from 'vuex'
import { Navigation, Pagination, Scrollbar, A11y, FreeMode} from 'swiper/modules';

export default {
    data() {
        return {
            currentFlower: '',
            isFree: false,
            slides: 3,
            initialSlide: 1,
            isCentered: true,
            slideIndex: 1,
            // paginationColor: '#C8A2E5',
            // horizontalGap: 10,
            // paginationBottom: 30,
            // isPopupOpened: false
        }
    },
    computed: {
        ...mapGetters([
            'SELECTED_CATEGORY'
        ])
    },
    setup() {
        const onSwiper = (swiper) => {
            
        };
        const onSlideChange = (e) => {
        
        };
        const prev = ref(null);
        const next = ref(null);
        return {
            onSwiper,
            onSlideChange,
            modules: [Navigation, Pagination, Scrollbar, A11y, FreeMode],
            prev,
            next,
        };
    },
    components: {
        Swiper,
        SwiperSlide
    },
    methods: {
        ...mapActions([
            'RESET_PAGE_NUMBER',
            'GET_CATEGORY_VALUE',
            'SELECT_CATEGORY',
            'GET_PRODUCTS',
            'RESET_SEARCH_VALUE'
        ]),
        onSlideChange(e){
            this.slideIndex = e.activeIndex
        },
        redirectToCatalog(category, subcategory, slideIndex){
            // this.$emit('scrollToBlock')
            // this.initialSlide = slideIndex
            window.scrollTo(0, 1950)
            this.currentFlower = subcategory
            if (category == 'Цветы поштучно' && subcategory=='') {
                this.currentFlower = 'Цветы'
            }
            this.RESET_SEARCH_VALUE(); 
            this.GET_CATEGORY_VALUE(subcategory)
            this.RESET_PAGE_NUMBER()
            this.SELECT_CATEGORY(category);
            this.GET_PRODUCTS();
            this.$router.push({query: {'category': category, 'page': 1}})
        },
        selectCategory(){
            switch (this.slideIndex) {
                case 0:
                    this.redirectToCatalog('Авторские букеты', '')
                    break;
                case 1:
                    this.redirectToCatalog('Цветочные композиции', '')
                    break;
                case 2:
                    this.redirectToCatalog('Букеты', '')
                    break;
                case 3:
                    this.redirectToCatalog('Монобукеты', '')
                    break;
                case 4:
                    this.redirectToCatalog('Цветы поштучно', 'Хризантема')
                    break;
                case 5:
                    this.redirectToCatalog('Цветы поштучно', 'Роза')
                    break;
                case 6:
                    this.redirectToCatalog('Цветы поштучно', '')
                    break;
                case 7:
                    this.redirectToCatalog('Свадебные букеты', '')
                    break;
                case 8:
                    this.redirectToCatalog('Открытки', '')
                    break;
                // case 9:
                //     this.redirectToCatalog('Мягкие игрушки', '')
                //     break;
                case 9:
                    this.redirectToCatalog('Букеты до 3000', '')
                    break;
                default:
                    break;
            }
        }
    },
    beforeMount() {
        if(window.screen.width <= 768){
            this.isFree = true
            this.slides = 'auto'
            this.initialSlide = 0
            this.isCentered = false
        }
    },
}
</script>

<style scoped>
    .main-categories__wrapper{
        position: relative;
        padding-bottom: 70px;
    }
    .main-categories__heading{
        margin-bottom: 60px;
        font-size: 36px;
        text-align: left;
    }
    .main-categories__inner{
        display: flex;
        gap: 16px;
        overflow-x: scroll;
        cursor: grab;
        padding-bottom: 15px;
    }
    /* .main-categories__inner::-webkit-scrollbar {
        width: 8px;
        height: 10px;        
    }
    .main-categories__inner::-webkit-scrollbar-track {
        background: #ECDEF6; 
        border-radius: 50px;       
    }
    .main-categories__inner::-webkit-scrollbar-thumb {
        background-color: #9E72C2;    
        border-radius: 20px;       
    } */
    .categories__item{
        /* width: 350px; */
        position: relative;
        display: flex;
        align-items: center;
        transition: all linear .2s;
        cursor: pointer;
    }
    .categories__item::before{
        position: absolute;
        content: "";
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        border-radius: 12px;
        background-color: rgba(0, 0, 0, .5);
        z-index: 1000;
        transition: all linear .2s;
    }
    .categories__item:hover{
        transform: scale(.95);
    }
    .item__img{
        border-radius: 12px;
        width: 100%;
        height: 100%;
    }
    .item__content{
        position: absolute;
        left: 15px;
        color: #fff;
    }
    .item__content{
        font-family: 'Playfair Display', serif;
    }
    .item__content_march-promo{
        position: absolute;
        font-family: 'Playfair Display', serif;
        color: #fff;
        text-transform: uppercase;
        left: 30px;
        bottom: 40px;
    }
    .item__name{
        font-size: 28px;
        font-weight: 500;
        margin-bottom: 10px;
        text-transform: uppercase;
        max-width: 260px;
    }
    .item__price{
        font-size: 18px;
        margin-bottom: 15px;
    }
    .item__show{
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
    }
    .item__name_fsz{
        font-size: 19px;
    }
    .item__name_price{
        font-size: 48px;
        display: inline-block;
        margin-top: -15px;
    }
    .activeCategory{
        opacity: 1;
    }
    .controlls__wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    }
    .slider__controlls{
        position: relative;
        height: 40px;
        width: 130px;
        bottom: 0;
    }
    .categories__select-btn{
        margin-right: 60px;
        font-family: "Bona Nova SC", serif;
        font-size: 21px;
        padding: 7px 15px 8px 15px;
        color: #595959;
        border: 1px solid #A6A6A6;
        border-radius: 12px;
        max-height: 40px;
        transition: all linear .2s;
    }
    .categories__select-btn:hover{
        background-color: #EFE7F5;
    }
    .swiper-button-prevv{
        position: absolute;
        left: 0;
        margin-top: 6px;
        padding: 10px;
        height: 25px;
        width: 50px;
        color: transparent;
        transition: all linear .2s;
        padding-left: 13px;
        background: url(../images/icons/arrow-prev.svg) no-repeat;
        background-position: center;
        cursor: pointer;
    }
    .swiper-button-prevv:hover{
        opacity: .5;
    }
    .swiper-button-nextt{
        position: absolute;
        right: 0;
        margin-top: 6px;
        padding: 10px;
        height: 25px;
        width: 50px;
        color: transparent;
        transition: all linear .2s;
        padding-left: 13px;
        background: url(../images/icons/arrow-next.svg) no-repeat;
        background-position: center;
        cursor: pointer;
    }
    .swiper-button-nextt:hover{
        opacity: .5;
    }
    .swiper-button-disabled{
        opacity: .5;
        pointer-events: none;
    }
    .categories__item{
        pointer-events: none;
    }
    .swiper-slide-active .categories__item::before{
        background-color: rgba(0, 0, 0, 0);
        pointer-events: all;
    }
    @media (max-width: 768px) {
        .item__name_price{
            font-size: inherit;
        }
        .main-categories__heading{
            margin-bottom: 15px;
            font-size: 20px;
            text-align: left;
        }
        .main-categories__inner{
            grid-template-columns: repeat(1, 1fr);
            gap: 5px;
        }
        .item__content{
            left: 20px;
        }
        .item__name{
            font-size: 22px;
            max-width: 150px;
            margin-bottom: 5px;
        }
        .item__price{
            font-size: 14px;
            margin-bottom: 5px;
        }
        .item__show{
            font-size: 10px;
        }
        .item__name_fsz{
            font-size: 12px;
        }
        .item__img{
            width: 100%;
            min-width: 320px;
            max-width: 320px;
        }
        .categories__item{
            max-width: 320px;
            pointer-events: all;
        }
        .categories-slider__slide{
            max-width: 320px;
        }
        .categories__item::before{
            display: none;
        }
        .slider__controlls{
            width: 110px;
        }
        .swiper-button-nextt{
            width: 40px;
            background-size: contain;
        }
        .swiper-button-prevv{
            width: 40px;
            background-size: contain;
        }
        .categories__select-btn{
            margin-right: 45px;
            font-size: 17px;
            padding-right: 5px;
            padding-left: 5px;
        }
        .main-categories__wrapper{
            padding-bottom: 0;
        }
    }
</style>