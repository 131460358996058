<template>
    <div class="categories">
        <div class="categories__inner">
             <button 
                :class="{active: SELECTED_CATEGORY == 'Все'}"
                class="categories__item"
                @click="selectCategory('Все')">Все</button>
            <button  
                v-for="category in CATEGORIES"
                :key="category.id" 
                @click="selectCategory(category.name)"
                :class="{active: category.name == SELECTED_CATEGORY, pop: category.name == 'Популярные букеты'}"
                class="categories__item">{{category.name}}</button>
        </div> 
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
     computed: {
        ...mapGetters([
            'CATEGORIES',
            'SELECTED_CATEGORY'
        ])
    },
    methods: {
        ...mapActions([
            'GET_CATEGORIES',
            'SELECT_CATEGORY',
            'GET_PRODUCTS',
            'RESET_PAGE_NUMBER',
            'RESET_SEARCH_VALUE',
            'GET_CATEGORY_VALUE'
        ]),
        selectCategory(category){
            this.GET_CATEGORY_VALUE('')
            this.SELECT_CATEGORY(category);
            this.RESET_PAGE_NUMBER();
            this.RESET_SEARCH_VALUE();
            this.GET_PRODUCTS();
            this.$router.push({name: 'catalog', query: {'category': category, 'page': 1}})
        }
    },
    mounted() {
        this.GET_CATEGORIES()
    },
}
</script>

<style scoped>
    .categories__inner{
        display: flex;
        flex-wrap: wrap;
    }
    .categories__item{
        color: #707070;
        margin-bottom: 20px;
        font-size: 16px;
        cursor: pointer;
        transition: color linear .1s;
        padding: 5px 15px;
        margin-right: 25px;
        border-radius: 5px;
    }
    .categories__item:hover{
        color: #000;
    }
    .active{
        font-weight: 700;
        color: #000;
        background-color: #F0EEEB;
    }
    .pop{
        position: relative;
        font-style: italic;
        font-weight: 700;
        border: 1px solid #CDB283;
    }
    /* .pop::after{
        position: absolute;
        content: "скидка 10%";
        color: #CDB283;
        padding-left: 5px;
        top: 24px;
        right: -20px;
        background: #fff;
        line-height: 65%;
    } */
    @media (max-width: 768px){
        .categories__inner{
            overflow-x: scroll;
            flex-wrap: nowrap;
            padding-bottom: 25px;
        }
        .categories__item{
            text-transform: uppercase;
            margin-bottom: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            margin-right: 40px;
            text-align: center;
            white-space: nowrap;
        }
        .categories__item:last-child{
            margin-right: 0;
        }
        /* .pop::after{
            position: absolute;
            content: "скидка 10%";
            color: #CDB283;
            padding-left: 5px;
            top: 35px;
            right: -20px;
            background: #fff;
            line-height: 65%;
        } */
    }
</style>