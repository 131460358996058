<template>
    <div class="popup">
        <div class="popup__inner">
            <div class="popup__form form">
                <button class="popup__close" @click="close"></button>
                <h1 class="form__heading heading">Форма регистрации</h1>
            
                <form 
                    class="form__reistration registration-form" 
                    @submit.prevent="registration">

                    <input 
                        placeholder="Введите Ваше имя" 
                        class="form__input input"
                        v-model="state.name"
                        :class="{invalid: v$.name.$error}"
                        >

                    <input 
                        placeholder="Введите Ваш телефон" 
                        class="form__input input"
                        v-model="state.tel"
                        v-imask="phoneNumberMask" 
                        maxlength="16"
                        :class="{invalid: v$.tel.$error}"
                        >

                    <input 
                        placeholder="Введите Ваш Email" 
                        class="form__input input"
                        v-model="state.email"
                        :class="{invalid: v$.email.$error}"
                        >

                    <input 
                        placeholder="Пароль" 
                        class="form__input input" 
                        type="password"
                        v-model="state.password"
                        :class="{invalid: v$.password.$error}"
                        >

                    <input 
                        placeholder="Повторите пароль" 
                        class="form__input form__input_repeat input" 
                        type="password"
                        v-model="state.confirmPass"
                        :class="{invalid: v$.confirmPass.$error}"
                        >

                    <div class="enter-form__btns">
                        <button
                            type="button" 
                            class="enter-form__enter enter-form__btn btn"
                            @click="selectLogin">Назад</button>
                        <button type="submit" class="enter-form__registration enter-form__btn btn">{{submitBtnText}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import useValidate from '@vuelidate/core'
import { required, email, minLength} from '@vuelidate/validators'
import { reactive } from 'vue'
import { IMaskDirective } from 'vue-imask';

export default {
    setup(){
        const state = reactive({
            name: '',
            tel: '',
            password: '',
            confirmPass: ''
        })
        const rules = {
            name: {required},
            password: {required},
            confirmPass: {required},
            tel: {required, minLength: minLength(16)},
            email: {required, email}
        }
        const v$ = useValidate(rules, state)
        return {state, v$}
    },
    data() {
        return {
            isLoading: false,
            phoneNumberMask: {
                mask: '+{7}(000)000-00-00',
                lazy: true
            }
        }
    },
    methods: {
        close(){
            this.$emit('closePopup', false)
        },
        selectLogin(){
            this.$emit('selectLogin')
        },
        async registration(){
            if(this.state.password !== this.state.confirmPass){
                return alert('Пароли не совпадают')
            }
            const isFormCorrect = await this.v$.$validate()
            if(!isFormCorrect){
                return alert('Ошибка в форме')
            }
            this.isLoading = true
            // let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios({
                method: 'POST',
                url: 'https://dff24.ru:8443/api/user/registration',
                data: {
                    email: this.state.email,
                    password: this.state.password,
                    tel: this.state.tel,
                    name: this.state.name
                }
            }).then((res)=>{
                if(res.data.message == 'Такой пользователь уже существует'){
                    alert('Пользователь с таким Email уже существует')
                }else{
                    document.cookie = `token = ${res.data.token}; max-age=259200`
                    this.$emit('success')
                }
            })
            // .then(()=>{
            //      axios({
            //         method: 'POST',
            //         url: 'https://dff24.ru:8443/api/cart/create',
            //         headers: {Authorization: `Bearer ${cookie[1]}`},
            //         params: {
            //             login: this.login
            //         }
            //     })
            // }).then(()=>{
            //     this.login = ''
            //     this.password = ''
            //     this.confirmPass = ''
            //     this.userRole = ''
            //     this.isUserLoading = false
            // })
        },
    },
    computed: {
        submitBtnText(){
            return this.isLoading ? 'Загрузка...' : 'Регистрация'
        }
    },
    directives: {
        imask: IMaskDirective
    }
}
</script>

<style scoped>
    .popup__inner{
        position: fixed;
        display: flex;
        z-index: 100;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 60px;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, .5);
    }
    .popup__close{
        position: absolute;
        top: 45px;
        right: 60px;
        height: 20px;
        width: 20px;
    }
    .popup__close::before{
        position: absolute;
        content: "";
        height: 2px;
        width: 25px;
        background-color: #707070;
        top: 12px;
        right: 0;
        transform: rotate(45deg);
    }
    .popup__close::after{
        position: absolute;
        content: "";
        height: 2px;
        width: 25px;
        background-color: #707070;
        top: 12px;
        right: 0;
        transform: rotate(-45deg);
    }
    .popup__form{
        position: relative;
        background-color: #fff;
        padding: 80px 160px;
        border-radius: 30px;
        max-width: 880px;
    }
    .form__heading{
        margin-bottom: 50px;
    }
    /* Форма входа */
    .form__enter{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 455px;
    }
    .form__input{
        margin-bottom: 20px;
    }
    .form__input_pass{
        margin-bottom: 25px;
    }
    .form__forgot-pass{
        align-self: flex-start;
        margin-bottom: 40px;
        font-size: 16px;
        text-decoration: underline;
    }
    .enter-form__btns{
        display: flex;
        margin-bottom: 35px;
    }
    .enter-form__btn{
        width: 100%;
        padding: 15px 0;
    }
    .enter-form__enter{
        width: 100%;
        margin-right: 25px;
    }
    .enter-form__checkbox{
        display: flex;
        justify-content: center;
    }
    .checkbox__checkbox{
        position: relative;
        display: inline-block;
        vertical-align: baseline;
        height: 20px;
        width: 20px;
        margin-right: 20px;
        margin-bottom: -2px;
        border: 1px solid #000;
        border-radius: 4px;
        cursor: pointer;
    }
    .checkbox__input{
        display: none;
    }
    .checkbox__label{
        cursor: pointer;
    }
    .checkbox_select{
        background-color: #000;
        transition: all linear .1s;
    }
    .checkbox_select::before{
        position: absolute;
        content: "";
        height: 12px;
        width: 2px;
        top: 3px;
        right: 6px;
        transform: rotate(35deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    .checkbox_select::after{
        position: absolute;
        content: "";
        height: 7px;
        width: 2px;
        bottom: 4px;
        left: 5px;
        transform: rotate(-35deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    /* Форма регистрации */
    .form__reistration{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 455px;
    }
    .form__input_repeat{
        margin-bottom: 25px;
    }
    .invalid{
        border-color: red;
    }
    @media (max-width: 600px) {
        .popup__inner{
            padding: 0;
        }
        .popup__form{
            padding: 60px 15px 30px 15px;
        }
        .popup__close{
            top: 25px;
            right: 15px;
        }
        .form__heading{
            margin-bottom: 30px;
        }
        .form__input{
            margin-bottom: 15px;
        }
        .enter-form__btns{
            margin-top: 15px;
        }
        .checkbox__label{
            font-size: 14px;
        }
    }
</style>