<template>
    <div class="sort">
        <div class="sort__inner">
            <div class="sort__text">Сортировать по:</div>
            <div class="select sort__select">
                <div 
                    class="select__current" @click="openSort"
                    :class="{rotate:isMenuOpened, undoRadius:isMenuOpened}">{{selected}}</div>
                <div class="select__body" v-if="isMenuOpened">
                    <span 
                        class="select__item" 
                        v-for="option in options"
                        :key="option.name"
                        @click="selectOption(option.name, option.value)"
                        :class="{disabled:option.name == selected}">{{option.name}}</span>
                </div>
            </div>
        </div>

         <div class="sort__inner sort__flower" v-if="SELECTED_CATEGORY == 'Цветы поштучно'">
            <div class="sort__text">Цветок:</div>
            <div class="select sort__select">
                <div 
                    class="select__current" @click="openSubcategory"
                    :class="{rotate:isSubMenuOpened}">{{SELECTED_SUBCATEGORY == '' ? subcategory : SELECTED_SUBCATEGORY}}</div>
                <div class="select__body" v-if="isSubMenuOpened">
                    <span 
                        class="select__item"
                        @click="selectSubcategory('Все')">Все цветы</span>
                    <span 
                        class="select__item" 
                        v-for="subcategory in SUBCATEGORIES"
                        :key="subcategory.id"
                        @click="selectSubcategory(subcategory.name)">{{subcategory.name}}</span>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    data() {
        return {
            selected: 'Новинки',
            subcategory: 'Все цветы',
            isMenuOpened: false,
            isSubMenuOpened: false,
            flowerCategory: '',
            options: [
                {name: 'Новинки', value: ''},
                {name: 'Цена по возрастанию', value: 'ASC'},
                {name: 'Цена по убыванию', value: 'DESC'}
            ]
        }
    },
    computed: {
        ...mapGetters([
            'SELECTED_CATEGORY',
            'SUBCATEGORIES',
            'SELECTED_SUBCATEGORY'
        ])
    },
    methods: {
        ...mapActions([
            'GET_SORT_DIRECTION',
            'GET_CATEGORY_VALUE',
            'RESET_PAGE_NUMBER',
            'GET_PRODUCTS'
        ]),
        openSort(){
            this.isMenuOpened = !this.isMenuOpened
        },
        openSubcategory(){
            this.isSubMenuOpened = !this.isSubMenuOpened
        },
        selectOption(name, value){
            this.selected = name
            this.isMenuOpened = false
            this.RESET_PAGE_NUMBER()
            this.GET_SORT_DIRECTION(value)
            this.GET_PRODUCTS()
        },
        selectSubcategory(name){
            this.subcategory = name
            this.isSubMenuOpened = false
            if(name == 'Все'){
                this.RESET_PAGE_NUMBER()
                this.GET_CATEGORY_VALUE('')
                this.GET_PRODUCTS()
                return
            }
            this.RESET_PAGE_NUMBER()
            this.GET_CATEGORY_VALUE(name) 
            this.GET_PRODUCTS()
        },
    },
}
</script>

<style scoped>

    .sort__inner{
        display: flex;
        align-items: center;
    }
    .sort__flower{
        margin-top: 20px;
        justify-content: space-between;
    }
    .sort__text{
        margin-right: 30px;
        color: #707070;
    }
    .select{
        position: relative;
        font-size: 16px;
    }
    .sort__select{
        min-width: 240px;
    }
    .select__current{
        position: relative;
        padding: 10px 20px;
        border: 1px solid #A6A6A6;
        border-radius: 12px;
        font-family: "Bona Nova SC", serif;
        transition: all linear .2s;
    }
    .select__current:hover{
        opacity: .5;
    }
    .select__current::after{
        position: absolute;
        content: "";
        top: 30%;
        right: 20px;
        height: 12px;
        width: 12px;
        border-right: 1px solid #595959;
        border-bottom: 1px solid #595959;
        transform: rotate(45deg);
        transition: all linear .2s;
    }
    .select__current:hover{
        cursor: pointer;
    }
    .select__body{
        font-family: "Bona Nova SC", serif;
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 100%;
        right: 0;
        background-color: #F0EEEB;
        padding: 0 10px;
        padding-bottom: 2px;
        width: 100%;
        z-index: 10;
        border-radius: 0 0 12px 12px;
    }
    .select__item{
        padding: 15px 0;
        border-bottom: 1px solid #000;
        margin-left: 10px;
        transition: all linear .2s;
        cursor: pointer;
    }
    .select__item:hover{
        opacity: .5;
    }
    .select__item:last-child{
        border: none;
    }
    .rotate::after{
        transform: rotate(225deg);
    }
    .sort__additional{
        margin-top: 15px;
        width: 100%;
        display: flex;
    }
    .sort__input{
        width: 100%;
        padding: 14px 15px;
        border: 1px solid #000;
        border-radius: 6px;
        font-size: 16px;
        font-family: 'Raleway', sans-serif;
        margin-right: 20px;
    }
    .sort__btn{
        padding: 15px 35px;
        border-radius: 6px;
        background-color: #ECDEF6;
        color: #7E46AD;
        font-size: 16px;
        align-self: flex-start;
        transition: all linear .2s;
        font-weight: 400;
    }
    .disabled{
        display: none;
    }
    .undoRadius{
        border-radius: 12px 12px 0 0;
    }
    @media (max-width: 1265px) {
        .sort__inner{
            flex-direction: column;
            align-items: flex-start;
        }
        .sort__text{
            margin-bottom: 10px;
        }
    }
    @media (max-width: 992px){
        .sort__select{
            min-width: 200px;
        }
    }
    @media (max-width: 768px){
        .sort__select{
            width: 100%;
        }
    }
    @media (max-width: 600px) {
        .sort__text{
            margin-bottom: 15px;
        } 
        .sort__input{
            border: 1px solid #A6A6A6;
            border-radius: 12px;
            padding: 9px 15px;
        }
        .sort__btn{
            padding-top: 11px;
            padding-bottom: 10px;
            border-radius: 12px;
        }
    }
</style>