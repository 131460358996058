<template>
    <div class="app">
        <router-view></router-view>
    </div>
</template>

<style>
    *,
    *::before,
    *::after{
        box-sizing: border-box;
    }
    *{
        margin: 0;
        padding: 0;
    }
    body{
        font-family: 'Raleway', sans-serif;
    }
    a, button{
        color: #000;
    }
    @font-face {
        font-family: 'MADE TheArtist Script';
        src: local('MADE TheArtist Script'), local('MADETheArtistScript'), url('./fonts/Madetheartistscript.woff2') format('woff2'), url('./fonts/Madetheartistscript.woff') format('woff'), url('./fonts/Madetheartistscript.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Lighthaus';
        src: url('./fonts/ofont.ru_Lighthaus.ttf');
    }
    @font-face {
        font-family: 'Mont';
        src: url('./fonts/Mont-Regular.ttf') format('truetype'), url('./fonts/Mont-Regular.woff') format('woff'), url('./fonts/Mont-Regular.woff2') format('woff2');
        font-weight: 400;
    }
    @font-face {
        font-family: 'Mont';
        src: url('./fonts/Mont-SemiBold.ttf') format('truetype'), url('./fonts/Mont-SemiBold.woff') format('woff'), url('./fonts/Mont-SemiBold.woff2') format('woff2');
        font-weight: 600;
    }
    @font-face {
        font-family: 'Nexa Demo';
        src: url('./fonts/NexaText-Trial-Light.ttf') format('truetype');
        font-weight: 300;
    }
    @font-face {
        font-family: 'Ouvality';
        src: url('./fonts/Ouvality.ttf') format('truetype');
        font-weight: 400;
    }
    button{
        background: none;
        outline: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
        font-family: 'Raleway', sans-serif;
    }
    .container{
        max-width: 1270px;
        padding: 0 15px;
        margin: 0 auto;
    }
    .tab{
        font-size: 16px;
        position: relative;
        margin-right: 20px;
        padding: 10px 20px;
        border-radius: 8px 8px 0 0;
        background-color: #fff;
        text-decoration: none;
    }
    .tab-item{
        position: relative;
        background-color: #fff;
        filter: drop-shadow(0px 1px 16px rgba(0, 0, 0, 0.10));
        border-radius: 0 10px 10px 10px;
        z-index: 50;
    }
    .input{
        padding: 12px 25px;
        border: 1px solid #707070;
        border-radius: 6px;
        font-size: 16px;
    }
    .page-bg{
        background: url(./images/flower-1.svg), url(./images/flower-2.svg);
        background-position-y: 108px, 108px;
        background-position-x: left, right;
        background-repeat: no-repeat;
    }
    .heading{
        font-size: 40px;
        text-transform: uppercase;
        text-align: center;
        font-family: 'Playfair Display', serif;
        color: #595959;
    }
    .header-offset{
        margin-bottom: 60px;
    }
    .btn{
        display: inline-block;
        text-transform: uppercase;
        color: #fff;
        font-weight: 700;
        background-color: #C8A2E5;
        border-radius: 6px;
        font-size: 16px;
        transition: all linear .2s;
    }
    .btn:hover{
        background-color: #ab6bdb;
    }
    @media (max-width: 992px){
        .page-bg{
            background-position-y: 99px, 99px;
            background-size: 30%;
        }
    }
    @media (max-width: 768px){
        .page-bg{
            background-position-y: 75px, 75px;
        }
    }
    @media (max-width: 600px) {
        .heading{
            font-size: 30px;
        }
        .page-bg{
            background: url(./images/flower-1-mob.svg), url(./images/flower-2.svg);
            background-repeat: no-repeat;
            background-size: 157px 187px, 177px 150px;
            background-position-y: 40px, 50px;
            background-position-x: left, 120%;
        }
        .header-offset{
            margin-bottom: 40px;
        }
    }
</style>
