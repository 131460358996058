<template>
    <div class="popup">
        <div class="popup__inner">
            <div class="popup__form form">
                <button class="popup__close" @click="close"></button>
                <h1 class="form__heading heading">Купить в один клик</h1>
                <form 
                    class="form__enter enter-form" 
                    @submit.prevent="makeOrder">
                    <input 
                        placeholder="Введите имя" 
                        class="form__input input"
                        v-model="state.name">
                    <input 
                        placeholder="Введите телефон" 
                        class="form__input form__input_pass input" 
                        v-imask="phoneNumberMask" 
                        maxlength="16"
                        v-model="state.tel">
                    <p class="form__notify">Наш менеджер перезвонит Вам, чтобы подтвердить заказ и уточнить детали</p>
                    <div
                        class="cart-form__checkboxes">
                        <span 
                            class="cart-form__checkbox"
                            :class="{checkbox_select: isAgree}"
                            @click="selectConfidential"></span>
                        <input 
                            id="radio__another" 
                            type="checkbox" 
                            class="checkbox">

                        <label 
                            for="radio__another" 
                            class="cart-form__label"
                            @click="selectConfidential">Согласие на обработку персональных данных в соответствии с <router-link to="/confidential">политикой конфиденциальности</router-link></label>
                    </div>
                    <div class="enter-form__btns">
                        <button 
                            class="enter-form__enter enter-form__btn btn"
                            type="submit"
                            >{{submitBtnText}}</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import useValidate from '@vuelidate/core'
import { required} from '@vuelidate/validators'
import { reactive } from 'vue'
import { IMaskDirective } from 'vue-imask';
import {mapGetters} from 'vuex'

export default {
    setup(){
        const state = reactive({
            name: '',
            tel: ''
        })
        const rules = {
            name: {required},
            tel: {required},  
        }
        const v$ = useValidate(rules, state)
        return {state, v$}
    },
    data() {
        return {
            isLoading: false,
            isAgree: false,
            phoneNumberMask: {
                mask: '+{7}(000)000-00-00',
                lazy: true
            }
        }
    },
    methods: {
        close(){
            this.$emit('closeOneClick', false)
        },
        selectConfidential(){
            this.isAgree = !this.isAgree
        },
        async makeOrder(){
            if (!this.isAgree) {
                return alert('Необходимо согласие на обработку персональных данных')
            }
            const isFormCorrect = await this.v$.$validate()
            if(!isFormCorrect){
                return alert('Ошибка в форме')
            }
            this.isLoading = true
            let order = []
            order.push(this.SELECTED_PRODUCT)

            axios({ 
                method: 'POST',
                url: 'https://dff24.ru:8443/api/orders/oneclick',
                headers: {
                    'Content-Type' : 'application/json'
                },
                data: JSON.stringify({ 
                    name: this.state.name,
                    tel: this.state.tel,
                    product: order,
                    price: this.totalPrice,    
                })
            })  
            .then(alert('Отправлено! Наш оператов перезвонит Вам в ближайшее время'))
            .then(location.reload())
        },
    },
    computed: {
        submitBtnText(){
            return this.isLoading ? 'Загрузка...' : 'Заказать'
        },
        ...mapGetters([
            'SELECTED_PRODUCT'
        ]),
        totalPrice(){
            return this.SELECTED_PRODUCT.price * this.SELECTED_PRODUCT.quantity + this.SELECTED_PRODUCT.relatedPrice
        }
    },
    directives: {
        imask: IMaskDirective
    },
}
</script>

<style scoped>
.cart-form__checkboxes{
        display: flex;
        align-items: center;
        margin-bottom: 25px;
    }
    .cart-form__label{
        cursor: pointer;
    }
    .cart-form__checkbox{
        position: relative;
        display: inline-block;
        vertical-align: baseline;
        min-height: 20px;
        min-width: 20px;
        margin-right: 20px;
        margin-bottom: -2px;
        border: 1px solid #000;
        border-radius: 4px;
    }
    .cart-form__checkbox:hover{
        cursor: pointer;
    }
    .checkbox{
        display: none;
    }
    .checkbox_select{
        background-color: #000;
        transition: all linear .1s;
    }
    .checkbox_select::before{
        position: absolute;
        content: "";
        height: 12px;
        width: 2px;
        top: 3px;
        right: 6px;
        transform: rotate(35deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    .checkbox_select::after{
        position: absolute;
        content: "";
        height: 7px;
        width: 2px;
        bottom: 4px;
        left: 5px;
        transform: rotate(-35deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    .popup__inner{
        position: fixed;
        display: flex;
        z-index: 1500;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 60px;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100%;
        background-color: rgba(0, 0, 0, .5);
    }
    .popup__close{
        position: absolute;
        top: 45px;
        right: 60px;
        height: 20px;
        width: 20px;
    }
    .popup__close::before{
        position: absolute;
        content: "";
        height: 2px;
        width: 25px;
        background-color: #707070;
        top: 12px;
        right: 0;
        transform: rotate(45deg);
    }
    .popup__close::after{
        position: absolute;
        content: "";
        height: 2px;
        width: 25px;
        background-color: #707070;
        top: 12px;
        right: 0;
        transform: rotate(-45deg);
    }
    .popup__form{
        position: relative;
        background-color: #fff;
        padding: 80px 160px;
        border-radius: 30px;
        max-width: 880px;
    }
    .form__heading{
        margin-bottom: 50px;
    }
    .form__enter{
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 455px;
    }
    .form__input{
        margin-bottom: 20px;
    }
    .form__input_pass{
        margin-bottom: 25px;
    }
    .enter-form__btns{
        display: flex;
        margin-bottom: 35px;
    }
    .enter-form__btn{
        width: 100%;
        padding: 15px 0;
    }
    .enter-form__enter{
        width: 100%;
    }
    .enter-form__checkbox{
        display: flex;
        justify-content: center;
    }
    .checkbox__checkbox{
        position: relative;
        display: inline-block;
        vertical-align: baseline;
        height: 20px;
        width: 20px;
        margin-right: 20px;
        margin-bottom: -2px;
        border: 1px solid #000;
        border-radius: 4px;
        cursor: pointer;
    }
    .checkbox__input{
        display: none;
    }
    .checkbox__label{
        cursor: pointer;
    }
    .checkbox_select{
        background-color: #000;
        transition: all linear .1s;
    }
    .checkbox_select::before{
        position: absolute;
        content: "";
        height: 12px;
        width: 2px;
        top: 3px;
        right: 6px;
        transform: rotate(35deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    .checkbox_select::after{
        position: absolute;
        content: "";
        height: 7px;
        width: 2px;
        bottom: 4px;
        left: 5px;
        transform: rotate(-35deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    .invalid{
        border-color: red;
    }
    .form__notify{
        text-align: center;
        margin-bottom: 25px;
        color: #707070;
    }
    @media (max-width: 600px) {
        .popup__inner{
            padding: 0 15px;
        }
        .popup__form{
            padding: 60px 15px 30px 15px;
        }
        .popup__close{
            top: 25px;
            right: 15px;
        }
        .form__heading{
            margin-bottom: 30px;
        }
        .form__input{
            margin-bottom: 15px;
        }
        .enter-form__btns{
            margin-top: 15px;
        }
        .checkbox__label{
            font-size: 14px;
        }
    }
</style>