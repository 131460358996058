import { createStore } from 'vuex'
import axios from 'axios';

export default createStore({
  state: {
    products: [],
    banners: [],
    orders: '',
    currentProduct: {},
    mainProductPhoto: '',
    selectedCategogy: 'Все',
    minPrice: 0,
    maxPrice: 30000,
    sortDirection: '',
    searchValue: '',
    categoryValue: '',
    deliveryCost: 0,
    discount: 0,
    cart: [],
    categories: [],
    subcategories: [],
    page: 1,
    totalPages: 0,
    cardsPerPage: 12,
    popularProducts: [],
    isConsultationCalled: false,
    selectedTariff: '',
    isFreeDelivery: false
  },
    getters: {
        SELECTED_TARIFF(state){
            return state.selectedTariff;
        },
        PRODUCTS(state){
            return state.products;
        },
        POPULAR_PRODUCTS(state){
            return state.popularProducts
        },
        SELECTED_PRODUCT(state){
            return state.currentProduct
        },
        MAIN_PHOTO(state){
            return state.mainProductPhoto
        },
        CART(state){
            return state.cart
        },
        DELIVERY_COST(state){
            return state.deliveryCost
        },
        CATEGORIES(state){
            return state.categories
        },
        SUBCATEGORIES(state){
            return state.subcategories
        },
        SELECTED_CATEGORY(state){
            return state.selectedCategogy
        },
        SELECTED_SUBCATEGORY(state){
            return state.categoryValue
        },
        DISCOUNT(state){
            return state.discount
        },
        CARDS_PER_PAGE(state){
            return state.cardsPerPage
        },
        TOTAL_PAGES(state){
            return state.totalPages
        },
        SELECTED_PAGE(state){
            return state.page
        },
        BANNERS(state){
            return state.banners
        },
        ORDERS(state){
            return state.orders
        },
        IS_CONSULTATION_CALLED(state){
            return state.isConsultationCalled
        },
    },
    mutations: {
        SET_PRODUCTS_TO_STATE: (state, products)=>{
            state.products = products
        },
        SET_TOTAL_PAGES: (state, value)=>{
            state.totalPages = Math.ceil(value / state.cardsPerPage)
        },
        SET_CATEGORIES: (state, categories)=>{
            state.categories = categories
        },
        SET_SUBCATEGORIES: (state, subcategories)=>{
            state.subcategories = subcategories
        },
        SET_ONE_PRODUCT: (state, data)=>{
            state.currentProduct = data
            state.mainProductPhoto = data.url
        },
        SET_MAIN_PHOTO: (state, url)=>{
            state.mainProductPhoto = url
        },
        SET_DELIVERY_COST: (state, cost)=>{
            state.deliveryCost = cost
        },
        SET_CART: (state, product)=>{
            if(state.cart.length){
                let isProductExist = false;
                state.cart.map(function(item){
                    if(item.id === product.id){
                        isProductExist = true;
                        item.quantity++
                    }
                })
                if(!isProductExist){
                    state.cart.push(product)
                }
            }else{
                state.cart.push(product)
            }
        },
        REMOVE_FROM_CART: (state, index)=>{
            state.cart.splice(index, 1)
        },
        INCREASE: (state, index)=>{
            state.cart[index].quantity++
            
        },
        DECREASE: (state, index)=>{
            if (state.cart[index].quantity > 1){
                state.cart[index].quantity--
            }
        },
        SET_DISCOUNT: (state, discount) =>{
            state.discount = discount
        },
        SET_PAGE: (state, value)=>{
            state.page = value
        },
        SET_DEFAULT_PAGE_NUMBER: (state)=>{
            state.page = 1
        },
        INCREACE_PAGE: (state)=>{
            if (state.page < state.totalPages) {
                state.page ++
            }
        },
        DECREACE_PAGE: (state)=>{
            if (state.page >1) {
                state.page --
            }
        },
        SET_SEARCH_VALUE: (state, value) => {
            state.searchValue = value
        },
        REMOVE_SEARCH_VALUE: (state)=>{
            state.searchValue = ''
        },
        SET_SELECTED_CATEGORY: (state, category)=>{
            state.selectedCategogy = category
        }, 
        SET_DEFAULT_CATEGORY:(state)=>{
            state.selectedCategogy = ''
        },
        SET_SORT_DIRECTION:(state, direction)=>{
            state.sortDirection = direction
        },
        SET_MIN_VALUE: (state, minVal)=>{
            state.minPrice = minVal
        },
        SET_MAX_VALUE: (state, maxVal)=>{
            state.maxPrice = maxVal
        },
        SET_POPULAR_PRODUCTS: (state, products)=>{
            // let gg = []
            // products.forEach(el => {
            //     el.oldPrice = el.price
            //     el.price = Math.ceil(el.price - el.price * 10 / 100) 
            //     gg.push(el)
                
            // });
            // products.rows = gg

            // state.products = products
            // state.popularProducts = products
            
            state.popularProducts = products
        },
        SET_BANNERS: (state, banners)=>{
            state.banners = banners
        },
        SET_CATEGORY_VALUE: (state, value)=>{
            state.categoryValue = value
        },
        SET_ORDERS_TO_STATE: (state, orders)=>{
            state.orders = orders
        },
        SET_ISCONSULTATION_CALLED: (state) =>{
            state.isConsultationCalled = true
        },
        SET_TARIFF: (state, tariff) =>{
            state.selectedTariff = tariff
        },
    },
    actions: {
        GET_PRODUCTS({commit}){
            if(this.state.searchValue != ''){
                return axios({
                    url: 'https://dff24.ru:8443/api/goods/search',
                    method: "GET",
                    params:{
                        searchVal: this.state.searchValue,
                        dir: this.state.sortDirection,
                        page: this.state.page
                    }

                }).then((products)=>{
                    commit('SET_PRODUCTS_TO_STATE', products.data);
                    commit('SET_TOTAL_PAGES', products.data.count);
                })
            }

            if(this.state.selectedCategogy !=='Все'){
                return axios({
                    url: 'https://dff24.ru:8443/api/goods/sort',
                    method: "GET",
                    params:{
                        category: this.state.selectedCategogy,
                        subcategory: this.state.categoryValue,
                        dir: this.state.sortDirection,
                        page: this.state.page,
                        maxVal: this.state.maxPrice,
                        minVal: this.state.minPrice,
                    }

                }).then((products)=>{
                    commit('SET_PRODUCTS_TO_STATE', products.data);
                    commit('SET_TOTAL_PAGES', products.data.count);
                    
                })
            }
            return axios(`https://dff24.ru:8443/api/goods/flowers/all`,{
                method: "GET",
                params:{
                    page: this.state.page,
                    dir: this.state.sortDirection,
                    maxVal: this.state.maxPrice,
                    minVal: this.state.minPrice,
                }
                }).then((products)=>{
                    commit('SET_PRODUCTS_TO_STATE', products.data);
                    commit('SET_TOTAL_PAGES', products.data.count);
                }).catch()
        },
        GET_MOST_POPULAR({commit}){
            return axios('https://dff24.ru:8443/api/goods/flowers/mostpopular', {
                method: 'GET',
                params: {
                    page: this.state.page
                }
            }).then((res)=>{
                commit('SET_PRODUCTS_TO_STATE', res.data)
                commit('SET_TOTAL_PAGES', res.data.count);
            })
        },
        GET_MOST_ORDERED({commit}){
            return axios('https://dff24.ru:8443/api/goods/flowers/mostordered', {
                method: 'GET',
                params: {
                    page: this.state.page
                }
            }).then((res)=>{
                commit('SET_PRODUCTS_TO_STATE', res.data)
                commit('SET_TOTAL_PAGES', res.data.count);
            })
        },
        GET_BANNERS({commit}){
            return axios.get('https://dff24.ru:8443/api/banner/get').then((res)=>{
                commit('SET_BANNERS', res.data)
            })
        },
        GET_CATEGORY_VALUE({commit}, value){
            commit('SET_CATEGORY_VALUE', value)
        },
        RESET_CATEGORY({commit}){
            commit('SET_DEFAULT_CATEGORY')
        },
        SELECT_CATEGORY({commit}, value){
            commit('SET_SELECTED_CATEGORY', value)
        },
        IS_DISCOUNT({commit}, discount){
            commit('SET_DISCOUNT', discount)
        },
        GET_POPULAR_PRODUCTS({commit}){
            return axios({
                url: 'https://dff24.ru:8443/api/goods/flowers/popular',
                method: "GET"
            }).then((products)=>{
                commit('SET_POPULAR_PRODUCTS', products.data);
            })
        },
        GET_CATEGORIES({commit}){
            return axios('https://dff24.ru:8443/api/category/all',
            {
                method: "GET",
            }).then((categories)=>{
                commit('SET_CATEGORIES', categories.data);
            })
        },
        GET_SUBCATEGORIES({commit}){
            return axios('https://dff24.ru:8443/api/subcategory/all',
            {
                method: "GET",
            }).then((subcategories)=>{
                commit('SET_SUBCATEGORIES', subcategories.data);
            })
        },
        GET_ONE_PRODUCT({commit}, data){
            commit('SET_ONE_PRODUCT', data)
        },
        SELECT_MAIN_PHOTO({commit}, url){
            commit('SET_MAIN_PHOTO', url)
        },
        ADD_TO_CART({commit}, product){
            commit('SET_CART', product);
        },
        INCREASE_QTY({commit}, index){
            commit('INCREASE', index)
        },
        DECREASE_QTY({commit}, index){
            commit('DECREASE', index)
        },
        DELETE_FROM_CART({commit}, index){
            commit('REMOVE_FROM_CART', index)
        },
        GET_DELIVERY_COST({commit}, cost){
            commit('SET_DELIVERY_COST', cost)
        },
        GET_PAGE_NUMBER({commit}, value){
            commit('SET_PAGE', value)
        },
        RESET_PAGE_NUMBER({commit}){
            commit('SET_DEFAULT_PAGE_NUMBER')
        },
        GET_SEARCH_VALUE({commit}, value){
            commit('SET_SEARCH_VALUE', value)
        },
        INCREASE_PAGE_NUMBER({commit}){
            commit('INCREACE_PAGE')
        },
        DECREASE_PAGE_NUMBER({commit}){
            commit('DECREACE_PAGE')
        },
        RESET_SEARCH_VALUE({commit}){
            commit('REMOVE_SEARCH_VALUE')
        },
        GET_SORT_DIRECTION({commit}, direction){
            commit('SET_SORT_DIRECTION', direction)
        },
        GET_MIN_VALUE({commit}, minVal){
            commit('SET_MIN_VALUE', minVal)
        },
        GET_MAX_VALUE({commit}, maxVal){
            commit('SET_MAX_VALUE', maxVal)
        },
        GET_ORDERS({commit}){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios.get('https://dff24.ru:8443/api/orders/orders-by-admin',
            {headers: {Authorization: `Bearer ${cookie[1]}`}
            }).then((res)=>{
                commit('SET_ORDERS_TO_STATE', res.data.rows)
                // this.orders = res.data.rows
            })
        },
        GET_SORTED_ORDERS({commit}, status){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios.get('https://dff24.ru:8443/api/orders/workstatus', 
            {headers: {Authorization: `Bearer ${cookie[1]}`},
            params:{
                workstatus: status
            }}).then((res)=>{
                commit('SET_ORDERS_TO_STATE', res.data.rows)
            })
        },
        TRIGGER_IS_CONSULTATION({commit}){
            commit('SET_ISCONSULTATION_CALLED')
        },
        GET_TARIFF({commit}, tariff){
            commit('SET_TARIFF', tariff)
        }
    }
})
